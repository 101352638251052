import { graphql } from 'gatsby';
import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";

import styled from 'styled-components';
import {device} from '../styles/Breakpoints'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import CornerIcons from '../components/CornerIcons';
import Header from '../components/Header';
import GalleryFeatureVideo from '../components/GalleryFeatureVideo'

import BlockContent from '@sanity/block-content-to-react';
import { Helmet } from "react-helmet";
import Cursor from '../components/Cursor';

const hoverColor = (props) => {
  return `
      rgba( ${props.data.site_hover_color.rgb.r}, 
        ${props.data.site_hover_color.rgb.g},
        ${props.data.site_hover_color.rgb.b},
        ${props.data.site_hover_color.rgb.a})
      `
}

const GalleryPageStyles = styled.main`
    .above-the-fold {
      position: relative;
      width: 100%;
      height: 100vh;
      /* border: 2px solid red; */
      display: flex;
      @media ${device.$medium} {
        display: block;
        height: auto;
      }
      .content {
        width: 50%;
        /* border: 1px solid purple; */
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media ${device.$medium} {
          width: 100%;
        }
        &__text {
          flex-direction: column;
          text-align: center;
          padding-right: 48px;
          text-transform: uppercase;
          h1 {
            font-weight: 700;
          }
          @media ${device.$medium} {
            justify-content: flex-start;
            padding-top: var(--header-height--desktop);
            padding-right: 0;
            height: auto;
            margin-bottom: 24px;
          }
          .subheading {
            line-height: 1;
            text-align: center;
            font-size: 14px;
            width: 60%;
          }
        }
        &__imagery {
          padding: var(--header-height--desktop) 0 72px;
          @media ${device.$medium} {
            padding: 0;
            margin-bottom: 36px;
          }
          .gatsby-image-wrapper {
            max-width: 100%;
            max-height: 100%;
            cursor: pointer;
          }
          video,
          mux-player {
            max-width: 100%;
            @media ${device.$medium} {
              width: 100%;
            }
          }
          .desktop {
            @media ${device.$medium} {
              display: none;
            }
          }
          .mobile {
            display: none;
            @media ${device.$medium} {
              display: block;
            }
          }
        }
      }
      .scroll-indicator {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 16px 0;
        width: 100%;
        /* border: 1px solid green; */
        cursor: pointer;
        @media ${device.$medium} {
          display: none;
        }
        .scroll-icon {
          background-size: cover;
          background-image: url("data:image/svg+xml,%3Csvg width='42' height='26' viewBox='0 0 42 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.4062 24.7188C20.25 25.5625 21.6562 25.5625 22.5 24.7188L40.7812 6.53125C41.625 5.59375 41.625 4.1875 40.7812 3.34375L38.625 1.1875C37.7813 0.343751 36.375 0.343751 35.4375 1.1875L21 15.625L6.46875 1.1875C5.53125 0.34375 4.125 0.34375 3.28125 1.1875L1.125 3.34375C0.28125 4.1875 0.28125 5.59375 1.125 6.53125L19.4062 24.7188Z' fill='%23C4C4C4'/%3E%3C/svg%3E");
          width: 40px;
          height: 24px;
          margin: 0 auto;
        }
      }
    }
    .description {
      position: relative;
      text-align: center;
      padding: 72px 0;
      width: 50%;
      margin: 0 auto;
      font-family: "Lora";
      line-height: 22px; 
      /* border: 2px solid purple; */
      @media ${device.$medium} {
        width: 100%;
        padding: 0 32px;
        margin-bottom: 72px;
      }
      #scroll-anchor {
        position: absolute;
        top: calc(-50vh + 72px);
        width: 100%;
      }
    }
    .slideshow {
      width: 100%;
      height: 100vh;
      padding: 72px 0;

      @media ${device.$medium} {
        padding: 72px 0 96px;
      }

      .slideshow-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        background-color: rgba(0,0,0,.4);
        backdrop-filter: blur(6px);
        cursor: pointer;
      }

      .slick-slider {
        height: 100%;
        z-index: 5;

        @media ${device.$medium} {
          width: calc(100% + 24px);
          margin-left: -12px;
        }

        .slick-arrow {
          padding: 48px;
          background-size: 24px;
        }

        .slick-prev {
          left: 48px;
        }

        .slick-next {
          right: 48px;
        }
      }

      .slick-list {
        height: 100%;
        @media ${device.$medium} {
          padding-left: 0px !important;
        }
      }

      .slick-track {
        height: 100%;
      }

      .slick-slide {
        padding: 0 25vw;
        display: flex;
        justify-content: center;
        align-items: center;
        > div {
          height: 100%;
        }
        .wrapper-div {
          height: 100%;
          .inner-div {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              max-height: 100%;
            }
          }
        }
        .gatsby-image-wrapper {
          height: 100%;
        }
        @media ${device.$medium} {
          padding: 0 12px;
        }
      }
      .slick-arrow {
        z-index: 20;
      }
    }
    .info-button {
      position: absolute;
      right: 16%;
      bottom: 24px;
      padding: 0 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #afafaf;
      border: 1px solid #afafaf;
      font-weight: 700;
      text-transform: uppercase;
      height: 50px;
      cursor: pointer;
      z-index: 20;
      transition: .2s all ease-in-out;
      &:hover {
        color: var(--color-black);
      }
      @media ${device.$medium} {
        right: 50%;
        transform: translateX(50%);
      }
    }

    .info-overlay {
      width: 100vw;
      height: 100vh;
      background: rgba(71, 71, 71, .6);
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      overflow: auto;
      
      @media ${device.$medium} {
          height: 100%;
          min-height: 100vh;
          padding-top: 118px;
          align-items: flex-start;
          overflow-y: auto;
        }
      .wrapper {
        width: 60vw;
        height: 70vh;
        /* border: 1px solid #fff; */
        position: relative;
        @media ${device.$medium} {
          width: 100vw;
          height: auto;
          padding: 0 24px 24px;
        }
        .info-box {
          max-width: 45%;
          position: absolute;
          background-color: rgba(255, 255, 255, .8);
          margin: 0 auto;
          @media ${device.$medium} {
            max-width: initial;
            position: relative;
          }
        }
        .gallery-title {
          top: 0;
          right: 0;
          width: 306px;
          min-height: 67px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: auto;
          text-align: center;
          padding: 0 24px;
          font-weight: 800;
          text-transform: uppercase;
          @media ${device.$medium} {
            top: initial;
            right: initial;
            margin-bottom: 48px;
          }
        }
        .credits-box {
          top: 43px;
          left: 0;
          padding: 24px;
          line-height: 24px;
          @media ${device.$medium} {
            top: initial;
            left: initial;
            margin-bottom: 24px;
          }
        }
        .cast-box {
          top: 112px;
          right: 0;
          text-align: center;
          padding: 24px 48px;
          a {
            transition: .2s all ease-in-out;
            &:hover {
              color: ${props => hoverColor(props)};
            }
          }
          @media ${device.$medium} {
            top: initial;
            right: initial;
            margin-bottom: 48px;
          }
        }
        .extra-button {
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          height: 54px;
          padding: 0 32px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-weight: 800;
          transition: .2s all ease-in-out;
          &:hover {
            transform: translateX(-50%) scale(1.02);
          }
          @media ${device.$medium} {
            left: initial;
            bottom: initial;
            display: flex;
            transform: translateX(0);
          }
        }
      }
    }

    .slideshow__close-button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 48px;
      z-index: 20;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 32px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 384 512'%3E%3Cstyle%3Esvg%7Bfill:%23ffffff%7D%3C/style%3E%3Cpath d='M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z'/%3E%3C/svg%3E");
    }

    &.slideshow-active {
      .slideshow {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 90;

        .slideshow-mask {
          display: block;
          z-index: 2;
        }
      }

      .slick-slider {
        cursor: pointer;
      }

      .slick-arrow {
        padding: 48px;
        background-size: 32px;
      }

      .slick-prev {
        left: 10vw;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 320 512'%3E%3Cstyle%3Esvg%7Bfill:%23ffffff%7D%3C/style%3E%3Cpath d='M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z'/%3E%3C/svg%3E");
      }

      .slick-next {
        right: 10vw;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 320 512'%3E%3Cstyle%3Esvg%7Bfill:%23ffffff%7D%3C/style%3E%3Cpath d='M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z'/%3E%3C/svg%3E");
      }

      .info-button {
        color: #ffffff;
        border: 1px solid #ffffff;
      }
    }
`

class GalleryTemplate extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        thisGallery: this.props.data.gallery,
        siteSettings: this.props.data.siteSettings.nodes[0],
        showInfoOverlay: false,
        slideShowActive: false
      };
  }

  componentDidMount() {
    // console.log('props', this.props)
  }

  scrollToContent = () => {

    const scrollDestination = document.getElementById('scroll-anchor')

    scrollDestination.scrollIntoView();

    // let intervalId = 0; // Need to cancel the scrolling when we're at the top of the page

    // function scrollStep() {
    //   // Check if we're at the top already. If so, stop scrolling by clearing the interval
    //   if (window.pageYOffset === 0) {
    //       clearInterval(intervalId);
    //   }
    //   window.scroll(0, window.pageYOffset - 50);
    // }

    // // Call the function scrollStep() every 6 millisecons
    // intervalId = setInterval(scrollStep, 6);
  }

  slideshowItems = () => {
    return this.state.thisGallery.images.map(item => {
      if (item.asset) {
        const source = item.asset.gatsbyImageData.images.fallback ? item.asset.gatsbyImageData.images.fallback.src : item.asset.url
        return (
          <div className="wrapper-div" key={ item._key }>
            {/* <GatsbyImage image={item.asset.gatsbyImageData} imgStyle={{ objectFit: "contain" }}/> */}
            <div className="inner-div">
              <img src={source} alt={item.asset.altText}/>
            </div>
            
          </div>
          
        )
      }
      else {
        return null;
      }
    });
  }

  toggleInfoOverlay = () => {
    this.setState({ showInfoOverlay: !this.state.showInfoOverlay });
  }


  

  render() {
    // console.log('THIS GALLERY', this.state.thisGallery)

    const gallery = this.state.thisGallery;
    const cornerIconData = this.props.data.cornerIconData.nodes[0];
    const siteSettings = this.props.data.siteSettings.nodes[0];

    // const slideShowItems = []

    //TODO add in entire check for images, or site will break
    let featureImageDesktop;
    let featureImageMobile;
    let galleryHasImages;
    if (gallery.images.length) {
      featureImageDesktop = gallery.feature_image_desktop ? gallery.feature_image_desktop.asset.gatsbyImageData : gallery.images[0].asset.gatsbyImageData;
      featureImageMobile = gallery.feature_image_mobile ? gallery.feature_image_desktop.asset.gatsbyImageData : featureImageDesktop;
      galleryHasImages = true;
    }
    else {
      featureImageDesktop = '';
      featureImageMobile = ''
      galleryHasImages = false;
    }
    

    const slickSettings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: 'progressive',
      centerMode: true,
      centerPadding: '24px',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false
          }
        }
      ]
    }

    const openGallerySlideshow = () => {
      this.setState({ slideShowActive: true });
    };

    const handleSliderClick = (event) => {
      if (event.target.classList.contains('slick-arrow')) return;

      this.setState({ slideShowActive: false });
    };

    return (
    <>
      <Helmet title={gallery.title + ' | Mimi Casting'}/>
      <GalleryPageStyles className={this.state.slideShowActive ? 'container slideshow-active' : 'container' } data={siteSettings}>
        <Header data={siteSettings}/>
        <CornerIcons data={cornerIconData} siteSettings={siteSettings}/>
        <Cursor 
          staticText={this.state.siteSettings.cursor_static}
          staticTextColor={this.state.siteSettings.cursor_static_color.hex}
          cursorSparkle={this.state.siteSettings.cursor_sparkle}
        />
        {
          galleryHasImages &&
          <>
          <div className="above-the-fold">
            <div className="content content__text">
              <h1>{gallery.title}</h1>
              <BlockContent 
                blocks={gallery._rawSubHeading} 
                renderContainerOnSingleChild={true}
                className="subheading"
              />
            </div>
            <div className="content content__imagery">
              {
                gallery.enable_feature_video && 
                <GalleryFeatureVideo data={gallery}/>
              }
              {
                !gallery.enable_feature_video && 
                <>
                  <GatsbyImage onClick={openGallerySlideshow} objectFit="contain" image={featureImageDesktop} className="desktop" alt={ gallery.title }/>
                  <GatsbyImage onClick={openGallerySlideshow} objectFit="contain" image={featureImageMobile} className="mobile" alt={ gallery.title }/>
                </>
              }
              
            </div>
            <button className="scroll-indicator btn--none" onClick={this.scrollToContent}>
              <div className="scroll-icon"></div>
            </button>
          </div>

          {
            gallery._rawDescription &&
              <div className="description" id="description">
                <div id="scroll-anchor"></div>
                <BlockContent 
                      blocks={gallery._rawDescription} 
                      renderContainerOnSingleChild={true}
                    />
              </div>
          }

          

          <div className="slideshow" onClick={(event) => handleSliderClick(event)} onKeyDown={(event) => handleSliderClick(event)} role="presentation">
            <div className="slideshow-mask" />
            <button className="slideshow__close-button btn--none" onClick={() => this.setState({ slideShowActive: false })}>
              <span className="visually-hidden">Close slideshow</span>
            </button>
            {
              !gallery._rawDescription &&
              <div id="scroll-anchor"></div>
            }
            <Slider {...slickSettings}>
              {
                this.slideshowItems()
              }
            </Slider>
            {
              gallery.enable_info_button &&
              <div className="info-button" onClick={ this.toggleInfoOverlay } onKeyDown={this.toggleInfoOverlay} role="button" tabIndex={0}>INFO</div>
            }
          </div>

          {
            this.state.showInfoOverlay &&
            <div className="info-overlay" onClick={ this.toggleInfoOverlay } onKeyDown={this.toggleInfoOverlay} role="button" tabIndex={0}>
              <div className="wrapper">
                <div className="info-box gallery-title">{gallery.title}</div>
                  {
                    gallery._rawCastCredits &&
                    <div className="info-box credits-box">
                      <BlockContent 
                        blocks={gallery._rawCastCredits} 
                        renderContainerOnSingleChild={true}
                      />
                    </div>
                  }
                  
                  {
                    gallery._rawCastSocials && 
                    <div className="info-box cast-box">
                      <BlockContent 
                        blocks={gallery._rawCastSocials} 
                        renderContainerOnSingleChild={true}
                      />
                    </div>
                  }
                  
                  {
                    gallery.extra_info_button_label &&
                    <a href={gallery.extra_info_button_url} className="info-box extra-button">
                      {gallery.extra_info_button_label}
                    </a>
                  }
              </div>
            </div>
          }
        </>
      }
      </GalleryPageStyles>
    </>
    )
  }

}

export default GalleryTemplate



export const query = graphql`
    query($slug: String!) {
        gallery: sanityGallery(slug: {
            current: { eq: $slug }
        }){
          title
          _rawSubHeading
          _rawDescription
          enable_cover_video
          enable_feature_video
          feature_image_desktop {
            asset {
              gatsbyImageData(layout: CONSTRAINED)
              altText
            }
          }
          feature_image_mobile {
            asset {
              gatsbyImageData(layout: CONSTRAINED)
              altText
            }
          }
          feature_video {
            mux_video {
              asset {
                playbackId
              }
            }
            video_file {
              asset {
                url
                mimeType
              }
            }
          }
          feature_video_vimeo
          images {
            _key
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 1000)
              altText
              url
            }
          }
          enable_info_button
          _rawCastCredits
          _rawCastSocials
          extra_info_button_label
          extra_info_button_url
        }
        cornerIconData: allSanityCornerIcons {
          nodes {
            corner_icon__top_left_url
            corner_icon__top_right_url
            corner_icon__bottom_left_url
            corner_icon__bottom_right_url
            corner_icon__center_left_url
            corner_icon__top_left_image {
              asset {
                gatsbyImageData(width: 156, layout: CONSTRAINED)
              }
            }
            corner_icon__top_right_image {
              asset {
                gatsbyImageData(width: 156, layout: CONSTRAINED)
              }
            }
            corner_icon__bottom_left_image {
              asset {
                gatsbyImageData(width: 156, layout: CONSTRAINED)
              }
            }
            corner_icon__bottom_right_image {
              asset {
                gatsbyImageData(width: 156, layout: CONSTRAINED)
              }
            }
            corner_icon__center_left_image {
              asset {
                gatsbyImageData(width: 156, layout: CONSTRAINED)
              }
            }
          }
      } 
      siteSettings: allSanitySiteSettings {
        nodes {
          logo {
            asset {
              extension
              gatsbyImageData(width: 300, layout: CONSTRAINED)
              url
            }
          }
          site_hover_color {
            rgb {
              r
              g
              b
              a
            }
          }
          cursor_sparkle
          cursor_static
          cursor_static_color {
            hex
          }
        }
      }
    }
`