import React from 'react'
import MuxPlayer from "@mux/mux-player-react"; 

import styled from 'styled-components';

import { device } from '../styles/Breakpoints.js';

const FeatureVideoStyles = styled.div`
    display: flex;
    width: 100%;
    background-color: #dadaad;
    @media ${device.$medium} {
    }

    mux-player {
        --controls: none;
        --media-object-fit: cover;
        --media-object-position: center;
    }
`

export default function FeatureVideo({data}) {

    return (
        <FeatureVideoStyles>
            {
                data.feature_video && data.feature_video.mux_video &&
                <MuxPlayer
                    playbackId={data.feature_video.mux_video.asset.playbackId}
                    streamType="on-demand"
                    muted={true}
                    autoPlay
                    loop
                />
            }
            {
                data.feature_video && !data.feature_video.mux_video &&
                <video playsInline autoPlay muted loop className="internal-feature">
                    <source src={data.feature_video.video_file.asset.url} type={data.feature_video.video_file.asset.mimeType} ></source>
                </video>
            }
            {
                !data.feature_video &&
                <div className="vimeo-feature" dangerouslySetInnerHTML={{ __html: data.feature_video_vimeo }}></div>
            }
            

        </FeatureVideoStyles>
    )
}
